.sign-div1 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 52px 0px 236px 0px;
  }
  .sign-div2 {
    width: 80%;
  }
  .input-log-in {
    padding: 20px;
  
    outline: none;
    border: 0.2px solid #80808082;
    border-radius: 5px;
  
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .div-head-login-from {
  }
  .login-filed {
  }
  
  .login-form-section {
    display: flex;
    justify-content: center;
  }
  .head-sign {
    font-size: 50px;
  
    text-align: center;
  
 
  }
  .forg-p {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    color: black;
  }
  .forg-p:hover{
  
      color: gray;
    }
    .btn-sign-in{
  
      
      border: 1px solid;
      padding: 20px;
      border-radius: 5px;
      background: #FF9417;
      color: white;
      width: 100%;
    }
    .form-log-d5{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 420px;
    }
  
  @media only screen and (max-width:500px) {
    .form-log-d5{
  
      width: unset !important;
    }
    .sign-div1 {
  
      padding: 10px 0px 100px 0px;
  }
  
  }
  
  .login-para{
    font-size: 15px;
    color: #a0a0a0;
    text-align: center;
  }

  .head-d-form-lo{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .log-lposter{
    width: 100%;
    height: 700px;
  }
  .log-left{
  width: 50%;
  }
  .log-right {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    padding: 278px 0px 0px 0px;
    align-items: center;
}
    .log2{
      background-image: url('../../assets/img//login-poster.webp');
      height: 100vh;
      background-position: center ;
      background-size: cover;
    }
    .log-3 {
      display: flex;
      justify-content: flex-end;
  }
  .log-h1{
    text-align: center;
    font-size: 30px;
  }
  .log-p1 {
    font-size: 18px;
    font-weight: bold;
    color: #ff9417;
    text-transform: capitalize;
}
.form-log-no{
  width: 500px;
  padding: 1pc 0pc;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;
}
.log-continue-btn1>button{
  width: 100%;
  background: #ff9417;
  padding: 17px;
  border-radius: 5px;
  color: white;
height: 60px;

}

@media only screen and (max-width:1200px) {


.log-right{
width: 70%;
}


}

@media only screen and (max-width:760px) {


  .log-3 {
    display: flex;
    justify-content: center;
  }
  
.log-right{
  width: 90%;
  }

  .log2 {
    background-image: none !important;
    height: unset !important;
    background-position: center;
    background-size: cover;
}
.log-right {
  padding: 88px 0px 71px 0px;
}

.log-p1 {
  font-size: 14px;

}

.form-log-no {
gap: 30px;
}
  }

@media only screen and (max-width:500px) {


  .form-log-no {
    width: 100%;
    padding: 1pc 0pc;
}



.form-log-no {
  flex-direction: column;
}
.log-continue-btn1>button {

  height: auto;
}
}
.react-ph{
  display: flex;
}
.react-tel-input{
  display: flex;

}

.react-tel-input .form-control {
height: 60px !important;
}
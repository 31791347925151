
.div-acc-1{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0px;
}
.div-acc-2{
  width: 90%;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.lh-a{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px;
    border-radius: 5px;
}
.rh-a{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding:15px  20px;
    border-radius: 5px;
    width: 240px;

}
.head-acc{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 12px;
  border-radius: 5px;
}
.head-ach1{
    font-size: 20px;
}
.show-add {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px;
}
.address-p{
    font-size: 13px;

}
.name-add{
    font-size: 12px;

}

.phone-no-ad {
    font-weight: 300;
    font-size: 13px;
}
.phone-no-ad>span{
    font-weight: 700;
    font-size: 13px;
}

.p-acc-det{

    font-size: 13px;
    padding: 10px 0px;
}
.defaultaddress{
    font-size: 12px;
}
.def-add{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media only screen and (max-width:550px) {
    .div-acc-1{
        padding: 20px 0px;
    }

}
.input-form-check[type="text"] {
    box-sizing: border-box;
    width: 100%;
    height: calc(3em + 2px);
    margin: 0 0 1em;
    padding: 6px;
    border: 1px solid #bababa;
    border-radius: 3px;
    background: #fff;
    font-size: 16px;
    resize: none;
    outline: none;
  }
  .input-form-check[type="text"]:focus {
    border-color: black;
  }
  .input-form-check[type="text"]:focus + label[placeholder]:before {
    color: black;
  }
  .input-form-check[type="text"]:focus + label[placeholder]:before,
  .input-form-check[type="text"]:valid + label[placeholder]:before {
    transition-duration: 0.2s;
    transform: translate(0, -1.6em) scale(0.9, 0.9);
    font-weight: bold;
  }
  .input-form-check[type="text"]:valid {
    border-color: black;
  }
  .input-form-check[type="text"]:valid + label[placeholder]:before {
    color: black;
  }
  .input-form-check[type="text"]:invalid + label[placeholder][alt]:before {
    content: attr(alt);
  }
  .input-form-check[type="text"] + label[placeholder] {
    display: block;
    pointer-events: none;
    line-height: 1.25em;
    margin-top: calc(-3em - 2px);
    margin-bottom: calc((3em - 1em) + 2px);
  }
  .input-form-check[type="text"] + label[placeholder]:before {
    content: attr(placeholder);
    display: inline-block;
    margin: 0 calc(0.5em + 2px);
    padding: 0 2px;
    color: #7d7d7d;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff);
    background-size: 100% 5px;
    background-repeat: no-repeat;
    background-position: center;
  }
  

  .check-out-btnn-cart1 {
    border: 1px solid;
    padding: 10px;
    border-radius: 5px;
    background: black;
    color: white;
    width: 100%;
}
.check-h1 {
    font-size: 17px;
}
.form-check-address {
    padding: 25px 0px;
}


.right-h-cart1 {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding:10px  20px;
  border-radius: 5px;
}
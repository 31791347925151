.p-d1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
.p-d2 {
  width: 90%;
}
.p-d3 {
  display: flex;
  justify-content: center;
}

.li-pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.li-spa1 {
  border: 1px solid;
  padding: 5px 11px;
  border-radius: 50%;
  font-size: 12px;
}
.li-spa2 {
  font-size: 10px;
}
.ul-pro {
  display: flex;
  justify-content: center;
}
.d-hr {
  border: none;
  border-top: 3px double #333;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
}

.d-hr:after {
  background: #fff;
  content: "§";
  padding: 0 4px;
  position: relative;
  top: -13px;
}
.ul-pro {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.hr-11 {
  border: 1px solid;
  width: 70px;
  border-radius: 5px;
}

.progress-steps {
  display: flex;
  justify-content: center;
}

.stepper-wrapper {
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #ccc;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;

  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.steps-f-size {
  font-size: 10px;
}
.select-c-d1 {
  border-top: 1px solid lightgrey;
  padding: 10px 0px;
}

.head-s-c-1 {
  font-size: 15px;
  font-weight: 100;
}
.p-s-section {
  width: 500px;
}

.box-color-select1 {
  padding: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  cursor: pointer;
}

.box-color-select2 {
  padding: 50px;
  background-color: rgb(253, 191, 215);
  border-radius: 5px;
  cursor: pointer;
}
.box-color-select3 {
  padding: 50px;
  background-color: rgb(209, 123, 86);

  border-radius: 5px;
  cursor: pointer;
}

.box-color-select4 {
  padding: 50px;
  background-color: rgb(251, 209, 52);

  border-radius: 5px;
  cursor: pointer;
}
.box-color-select5 {
  padding: 50px;
  background-color: rgb(95, 86, 84);

  border-radius: 5px;
  cursor: pointer;
}
.box-color-select6 {
  padding: 50px;
  background-color: rgb(232, 185, 153);

  border-radius: 5px;
  cursor: pointer;
}
.box-color-select7 {
  padding: 50px;
  background-color: rgb(174, 35, 60);

  border-radius: 5px;
  cursor: pointer;
}
.box-color-select8 {
  padding: 50px;
  background-color: rgb(252, 252, 252);
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid lightgray;
}

.d-f-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 10px 0px;
}

.selected-c {
  padding: 5px;
  border: 2px solid #ffd835;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.select-color-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 20px;
}
.rel {
  padding: 5px;
  position: relative;
  border: 2px solid transparent;
}
.select-sizh1 {
  font-size: 15px;
  font-weight: bold;
}
.size-sec {
  display: flex;
  gap: 10px;
  padding: 10px 0px;
}
.select-size {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.span-size-s {
}
.size-box {

  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.sel-d1 {
  display: flex;
  justify-content: space-between;
}
.select-Guide {
  color: #ff9417;
  font-size: 12px;
  cursor: pointer;
}
.size-boxs {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: black;
}
.btn-next {
  background-color: #ff9417;
  width: 100%;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.btn-next-pro {
  margin-top: 50px;
  position: relative;
  z-index: 100;
}
.img-size-guide {
  width: 315px;
}
.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(4px);
  z-index: 1000;
}
.modal-pro1 {
}
.close-m1 {
  display: flex;
  justify-content: flex-end;
}
.close-m1 > svg {
  cursor: pointer;
}
.size-g-h1 {
  text-align: center;
  font-size: 16px;
}
.modal-content-s {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.table-modal-size-guid {
  width: 100%;
  border-collapse: collapse;
}
.tr-table > th {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tr-table > td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 11px;
  text-align: center;
}
.tr-table > th {
  background-color: #f2f2f2;
  font-size: 11px;
  text-align: center;
}

/* Responsive table styles */

.modal-pro1 {
  width: 350px;
}

@media only screen and (max-width: 550px) {
  .p-s-section {
    width: unset !important;
  }
  .img-size-guide {
    width: 200px;
  }
  .modal-pro1 {
    width: 281px;
  }

  .back-image {
    width: 320px !important;
  }
  .front-image {
    width: 320px !important;
  }
  .step-2-c1 {
    width: unset !important;
  }
}

@media only screen and (max-width: 360px) {
  .img-size-guide {
    width: 160px;
  }
  .modal-pro1 {
    width: 200px;
  }
}
@media only screen and (max-width: 650px) {
  .btn-next-pro {
    margin-top: unset;

}
}


.custom-tab-class:focus {
  outline: none !important;
}

.react-tabs__tab:focus {
  outline: none !important;
}

.react-tabs:focus {
  outline: none !important;
}
.st2-c {
  display: flex;

  justify-content: center;
}
/* 
.image-container {
  position: relative;
  width: 200px; 
  height: 200px; 
  perspective: 1000px; 
  cursor: pointer;
}

.front-image,
.back-image {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front-image {
  z-index: 2;
  transform: rotateY(0deg);
  transition: transform 0.5s ease;
}

.back-image {
  transform: rotateY(180deg);
  transition: transform 0.5s ease;
}

.image-container.flipped .front-image {
  transform: rotateY(180deg);
}

.image-container.flipped .back-image {
  transform: rotateY(0deg);
} */

.front-image {
  width: 500px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.back-image {
  width: 500px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.flip-icon {
  z-index: 50;
  position: absolute;
  display: flex;
  cursor: pointer;
  padding: 10px;
  background-color: hsla(0, 0%, 76.5%, 0.3);
  flex-direction: column;
  text-align: center;
}
.st2-d2 {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.step-2-btns {
  display: flex;
}
.step-2-c1 {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.btn-save-step2 {
  width: 100%;
  padding: 20px 10px;
  border: 1px solid #ff9417;
  color: #ff9417;
  background-color: white;
}
.btn-next-step2 {
  width: 100%;
  padding: 20px 10px;
  /* border: 1px solid; */
  background: #ff9417;
  color: white;
}
.btn-text-step2 {
  width: 100%;
  padding: 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-right: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.btn-gallery-step2 {
  width: 100%;
  padding: 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-step-2content {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
}

.para-modal-step2 {
}
.para-1-step2 {
  font-size: 13px;
}
.para-2-step2 {
  font-size: 12px;
}
.step-2-m1 {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}
.custom-file-input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.custom-file-label {
  background-color: #ff9417;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.modal-step-head {
  width: 350px;
}

@media only screen and (max-width: 400px) {
  .modal-step-head {
    width: 300px;
  }
}

@media only screen and (max-width: 350px) {
  .modal-step-head {
    width: unset;
  }
  .modal-pro3{
    width:100% !important;
  }
}

.add-text-f {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
  font-size: 12px;
  border-radius: 5px;
  width: 80%;
  text-align: center;
}
.btn-add-t {
  width: 54px;
  height: 54px;
  margin-left: 8px;
  border-radius: 5px;
  background-color: #ff9417;
  color: white;
  border: none;
}

.btn-add-t:active {
  transform: scale(0.8);
}

.form-content-step-2 {
  display: flex;
  /* padding: 10px; */
}
.t-section-content {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  border-radius: 5px;
}
.close-t-section {
  display: flex;
  justify-content: flex-end;
}
.close-t-section > svg {
  cursor: pointer;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  padding: 2px;
  border: 1px solid lightgrey;
  background: white;
}
.add-text-f:focus {
  outline: none;
}
.max-t {
  font-size: 13px;
  margin-top: 5px;
  color: gray;
  padding: 10px;
}

.tab-container {
}

.tab-buttons {
  display: flex;
  padding: 10px;
}

.tab-buttons button {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.tab-buttons button.active {
  background-color: #ff9417;
  color: white;
}

.content-fonts-step2 {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 30px 10px;
  color: white;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.btn-add-font {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  width: 90px;
}
.font-ss-f {
  font-size: 22px;
}
.svg-chek-step2 {
  position: absolute;
  top: 0;
  right: 0;
}
.p-r-step2 {
  position: relative;
}
.ss-22ss2 {
  font-size: 12px;
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Oswald:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz@6..96&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
.tab-content {
  font-family: "Oswald", sans-serif;
  font-family: "Roboto", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: 'Satisfy', cursive;
  font-family: 'Chewy', cursive;
  font-family: "Open Sans", sans-serif;
  font-family: "Bebas Neue", sans-serif;
  font-family: "Comic Neue", cursive;
  font-family: "GFS Didot", serif;
  font-family: "Lato", sans-serif;
  font-family: "Lora", serif;
  font-family: "Lilita One", cursive;
  font-family: "Russo One", sans-serif;
  font-family: "Righteous", cursive;
  font-family: "Great Vibes", cursive;
  font-family: "Lobster Two", cursive;
  font-family: 'Indie Flower', cursive;
  font-family: "Bodoni Moda", serif;
  font-family: 'Bad Script', cursive;
}

.btn-select-color1 {
  background: rgb(231, 76, 60);
  border: 1px solid lightgray;
  padding: 30px;
  border-radius: 5px;
}

.btn-main--d1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

}
.font-colors-s{
  position: relative;
}
.hex-c-modal {
  display: flex;
  justify-content: center;
}
.btn-more-color{
  padding: 10px 0px;
  font-size: 15px;
}
.modal-pro3{
  width: 300px;
}
.hex-c-modal2{
  display: flex;
  flex-direction: column;
  align-items: center;

}
.modal-set-1 {
  display: flex;
  justify-content: center;
  align-items: center;
} 
.step-w1 {
  display: flex;
  margin-top: 20px;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;

}
.step-w2 {
  display: flex;
  margin-top: 20px;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.step-w3{
  display: flex;

  margin-top: 20px;
  gap: 5px;
}
.size-step{
  padding: 20px;
  background: #F6F7F8;

}
.size-step2{
  padding: 50px;
  background: #F6F7F8;

}
.size-step3 {
  padding: 18px;
  background: #F6F7F8;
  /* border-radius: 491px; */
  border-radius: 100%;
}
.size-step4{
  padding: 5px 40px;
  background: #F6F7F8;

}
.step-si {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.size-step6 {
  padding: 25px;
  background: #F6F7F8;
  margin-top: 20px;
  /* border-radius: 491px; */

}

.section-resize-img-p{

}

.t4 {
  position: absolute;
}

.section-resize-img-p {
  display: flex;
  justify-content: center;
}

.span-flip-i{
  -webkit-user-select: none!important;
  user-select: none!important;

}



.d-f-cp{
  display: flex;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}


.span-size-s {
  font-size: 12px;
}
.scroll-to-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    border: 1px solid transparent;
    background-color: #111215;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;

    z-index: 50;
  }
.scroll-to-top:hover {
  border: 1px solid  rgb(209, 123, 86);
  transition:  0.8s;


}
  
  .scroll-to-top.show {
    display: block;
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInRight {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInRight {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 
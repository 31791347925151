.banner-img{
width: 100%;
}
.banner-img-home{

}
.home-d3 {
    width: 100%;
    display: flex;
    padding: 50px 0px;
    justify-content: center;
}
.home-d4{
    width: 80%;

}
@media only screen and (max-width: 900px) {
    .home-d4{
        width: 100%;
    
    }
}

.pp-desigin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 0px 0px 25px 0px;
}
.pp-desigin>h1{

    font-size: 20px;

}

.pp-desigin>p{

    font-size: 14px;
    color: gray;

}
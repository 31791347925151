*{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;


}
ul{
  list-style: none;
}
li{
  list-style: none;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


button {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: 0!important;
}

a{
   text-decoration: none;
   color: unset;
}

.tt2{
  position: relative;
  display: flex;
  justify-content: center;
}
.tt3{

}
.t4{
  position: absolute;
}
@media only screen and (max-width: 550px) {
  .tt33>img{
    width: 350px;

  }
}
.stage-img-c{
  /* width: 300px;
  height: 300px; */
}
.konvajs-content>canvas{
    /* width: 300px;
  height: 300px; */
}


::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #fff;
}
.top-cart-h2{
display: flex;
}
.span-1-cart{
    font-size: 11px;
}
.span-2-cart{
    font-size: 12px;
}
.top-cart-h2 {
    display: flex;
    flex-direction: column;
}
.header-cart-show {
    display: flex;
    justify-content: space-around;
    padding: 10px 20px;
    align-items: center;
}
.card-d1 {
    display: flex;
    justify-content: center;
    padding: 50px 0px 156px 0px;
}
.card-d2{
    width: 80%;
    
}
.count-bag{
    font-size: 17px;
}
.inner-count-cart{
font-weight: 200;
}
.left-h-cart{

}
.right-h-cart{

}
.l-h-c {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.r-h-c>img{

    width: 100px;
    border-radius: 5px;

}
.inner-content {
    display: flex;
    width: 400px;
    justify-content: space-between;
    padding: 20px;
    margin-top: 10px;

    border-radius: 5px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.cart-content{
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    gap: 5px;
}
.lh-p1cart{
    font-size: 12px;
}
.lh-p1cart2{
    font-size: 10px;

}
.cart-p-price{
    font-size: 18px;

}
.s-qty-div {
    display: flex;
    justify-content: space-between;
}
.s-cc {
    border: 1px solid lightgray;
    padding: 5px 11px;
    border-radius: 5px;
}
.qty-cc{
    border: 1px solid lightgray;
    padding: 5px 11px;
    border-radius: 5px;
}
.s-cc>h1{
    font-size: 12px;
    font-weight: 100;
}
.qty-cc>h1{
    font-size: 12px;
    font-weight: 100;
}
.span-h-carts{
    font-weight: bold;
}

.main-r-c{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    padding:  10px 0px;
    border-radius: 5px;

}

.right-cart-content{
    width: 400px;
}
.coupon-add {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ff94173d;
    color: #FF9417;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 10px;
}
.coupon-add>p{
    font-size: 12px;
}
.delete-item-pro {
    position: absolute;
    right: 5px;
    top: 4px;
    cursor: pointer;
  
}
.delete-item-pro>svg {
background-color: white;
padding: 5px;
border-radius: 50%;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.price-summary {
    margin: 10px 0px;
}
.price-summary>p{
    font-size: 12px;
    background: lightgray;
    padding: 10px;
}
.prce-details-total {
    display: flex;
    justify-content: space-between;
}
.prce-details-total>p{
    font-size: 12px;
}
.p1-c-c1{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid lightgray;
}
.total-se-cart {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}
.l-h-c-r1>span{
    font-size: 12px;

}
.l-h-c-r1>p{
    font-size: 14px;

}
.l-h-c-r1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.r-h-c-r1>button{
    background: #FF9417;
    padding: 10px;
    border-radius: 5px;
    color: white;
}

@media only screen and (max-width: 1000px) {

    .cart-content {
  
        flex-wrap: wrap;
    }
    .right-cart-content {
        width: unset !important;

    }
    .inner-content {
width: unset !important;
    }
    .inner-content2  {
        width: unset !important;
            }
            .inner-content3  {
                width: unset !important;
                    }
    
    .left-h-cart {
        width: 100%;
    }
    .right-h-cart {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {

    .inner-content {
     
        justify-content: space-between;
  
        flex-direction: column-reverse;
        align-items: center;
    }
    .header-cart-show {

        padding: 6px 4px;
   
    }
}
.r-h-c-r1{
  
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
}

.modal-cart-coupon{
    width: 400px;
}
.coupon-c-modal{

}
.para-c-m {
    text-align: center;
    font-size: 14px;
}
.close-m2 {
    display: flex;
    justify-content: space-between;
}
.close-m2>svg {
    cursor: pointer;

}
.apply-btn-Coupon{
    width: 100%;
}
.apply-btn-Coupon>button{
    background: #FF9417;
    width: 100%;
    padding: 10px 0px;
    color: white;
    border-radius: 5px;
   
}
.f-f-form-mm{
    display: flex;
    gap: 10px;
}
@media only screen and (max-width: 460px) {

    .modal-cart-coupon {
        width: 321px;
    }
}
@media only screen and (max-width: 380px) {
    .f-f-form-mm {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }
    .modal-cart-coupon {
        width: 221px;
    }
}



.empty-cart {
    padding: 100px 0px;
}
.empty-cart2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.empty-cart-img{
    width: 170px;
}
.e-para{
    font-size: 20px;
}
.e-btn-c {
 
    padding: 15px;
    border-radius: 5px;
    background: #FF9417;
color:white;
}


.inner-content2 {
    display: flex;
    width: 400px;
    justify-content: space-between;
    padding: 100px 20px;
    margin-top: 10px;
    border-radius: 5px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #F6F7F8;
}
.inner-content33 {
    display: flex;
    width: 400px;
    justify-content: space-between;
    padding: 150px 20px;
    margin-top: 10px;
    border-radius: 5px;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #F6F7F8;
}
.line-empty-cart{
display: flex;
}
.line-empty-cart2{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
 
    padding: 5px 50px;
    background: #F6F7F8;

}

.cart-data-d{
    
}
.headig-sh{
    font-size: 18px;
    text-align: center;
    padding: 10px 0px;

}
.rp-ontent>p {
    font-size: 16px;
}
.rp-ontent {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.headig-sh1{
    font-size: 18px;

    }
    .headig-sh2{
        font-size: 16px;
    
        }
        .headig-sh3{
            font-size: 14px;
        
            }
            .rp-ontent2>p {
                font-size: 16px;
            }
            .rp-ontent2 {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 50px;
            }

            .new-div1 {
                display: flex;
                justify-content: center;
                padding: 50px 0px;
            }
            .new-div2 {
                width: 90%;
            }
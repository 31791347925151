.input-form-check[type="text"] {
    box-sizing: border-box;
    width: 100%;
    height: calc(3em + 2px);
    margin: 0 0 1em;
    padding: 6px;
    border: 1px solid #bababa;
    border-radius: 3px;
    background: #fff;
    font-size: 16px;
    resize: none;
    outline: none;
  }
  .input-form-check[type="text"]:focus {
    border-color: black;
  }
  .input-form-check[type="text"]:focus + label[placeholder]:before {
    color: black;
  }
  .input-form-check[type="text"]:focus + label[placeholder]:before,
  .input-form-check[type="text"]:valid + label[placeholder]:before {
    transition-duration: 0.2s;
    transform: translate(0, -1.6em) scale(0.9, 0.9);
    font-weight: bold;
  }
  .input-form-check[type="text"]:valid {
    border-color: black;
  }
  .input-form-check[type="text"]:valid + label[placeholder]:before {
    color: black;
  }
  .input-form-check[type="text"]:invalid + label[placeholder][alt]:before {
    content: attr(alt);
  }
  .input-form-check[type="text"] + label[placeholder] {
    display: block;
    pointer-events: none;
    line-height: 1.25em;
    margin-top: calc(-3em - 2px);
    margin-bottom: calc((3em - 1em) + 2px);
  }
  .input-form-check[type="text"] + label[placeholder]:before {
    content: attr(placeholder);
    display: inline-block;
    margin: 0 calc(0.5em + 2px);
    padding: 0 2px;
    color: #7d7d7d;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff);
    background-size: 100% 5px;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .check-d-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 0px 130px 0px;
  }
  
  .check-d-2 {
    width: 90%;
  }
  .check-h1 {
    font-size: 17px;
  }
  
  .log-btn-check {
    border: 1px solid;
    padding: 5px 14px;
    border-radius: 15px;
  }
  .log-btn-div-check {
    padding: 20px 0px;
  }
  .log-btn-check:hover {
    background-color: rgba(128, 128, 128, 0.164);
  }
  .form-check-address {
    padding: 25px 0px;
  }
  .form-check-div2 {
  }
  .left-h-check {    /* width: 30%; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 24px;
    border-radius: 5px;
  }
  .d-f-c-8 {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .check-content-head-div {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: flex-start;
  }
  .check-proimg {
    width: 100px;
  }
  .check-out-btnn-cart1 {
    border: 1px solid;
    padding: 10px ;
    border-radius: 5px;


    background:  #FF9417;
    color: white;
    width: 100%;
  }
  @media only screen and (max-width:900px) {
    .check-content-head-div {
      display: flex;
      justify-content: center;
      gap: 5px;
      flex-wrap: wrap;
  }
  
  
  }
  @media only screen and (max-width:500px) {
  
  
  .check-d-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px 50px 0px;
  }
  .d-f-cart12 {
  
    gap: 6px !important;
  }
  }
  
  .d-f-car-6{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  
  }
  
  @media only screen and (max-width:600px) {
    .d-f-cart12 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 6px;
      flex-direction: column;
  }
  
  
  }
  
  @media only screen and (max-width:360px) {
    .d-f-car-6 {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    
  
  }
  
  .d-f-cart12 {
    display: flex;
  
    gap: 6px;
  
  }
  
  .address-adcheckout{
    padding: 10px 0px;
  }
  
  .address-adcheckout-h1{
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
  }
  
  .address-adcheckout-h1:hover{
  color: gray;
  }
  .check-d-2{
    width: 80%;
  }
  .check-d-1{
display: flex;
justify-content: center;
  }
  .div-2-cart {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  .heading-bag > h1 {
    font-size: 22px;
    padding: 25px 0px;
  }
  .heading-pay > h1 {
    font-size: 22px;
    font-size: 22px;
    padding: 16px 0px;
  }
  .product-img-cart > img {
    width: 160px;
  }
  .product-name-cart {
    font-size: 18px;
    font-weight: 500;
  }
  
  .p-price-cart {
    font-size: 18px;
    font-weight: 500;
  }
  .d-f-cart2 {
    display: flex;
    justify-content: space-between;
  }
  .cart-m-2 {
    width: 90%;
  }
  .cart-m-1 {
    width: 100%;
    display: flex;
    padding: 50px 0px 134px 0px;
    justify-content: center;
  }
  .left-h-cart {
    /* width: 50%; */
  }
  .right-h-cart {
    /* width: 20%; */
  }
  .lh-m1 {
  }
  .div-8-cart {
    display: flex;
    gap: 15px;
  }
  .d-f-cart-f {
    display: flex;
    gap: 15px;
  }
  .color-cart-p {
    font-size: 15px;
    font-weight: 500;
  }
  
  .d-f-cart-3 {
    display: flex;
    gap: 50px;
    align-items: center;
  }
  .d-f-cart-4 {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .d-f-cart-4 > svg {
    cursor: pointer;
  }
  
  .cart-gap-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .main-d-p-c {
    padding-bottom: 26px;
    border-bottom: 0.5px solid #80808085;
  
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .d-f-car-5 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .d-f-car-5 > h1 {
    font-size: 15px;
    font-weight: 500;
  }
  .head-pay-centent {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .border-b-c {
    border-bottom: 0.5px solid #80808085;
    padding: 12px 0px;
  }
  .check-out-btnn-cart{
  
    
    border: 1px solid;
    padding: 10px 127px;
    border-radius: 5px;
    background: black;
    color: white;
  }
  .check-out-btnn-cart:active{
  transform: scale(0.8);
  
  }
  @media only screen and (max-width:1234px) {
    .div-2-cart {
      display: flex;
      justify-content: center;
      gap: 50px;
      flex-wrap: wrap;
      flex-direction: column;
      
  }
  }

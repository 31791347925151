.logo-nav-img {
  width: 80px;
  cursor: pointer;
}
.logo-nav {
}
.nav-d1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;

    border-bottom: 1px solid lightgray;
  /* width: 100%; */

}
.l1:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.l1:hover:after {
  width: 100%;
  left: 0;
}
.li-black:after {
  content: "";
  background: none repeat scroll 0 0 transparent;
  display: block;
  height: 1px;
  background: gray  ;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.li-black:hover:after {
  width: 100%;
  left: 0;
}


.nav-d2{
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  padding: 10px;
  background: white;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid lightgray;
}


.ul-nav {
  display: flex;
  gap: 30px;
}
.ul-nav > li {
  cursor: pointer;
}
.navb-icons{
    display: flex;
    gap: 10px;

}
.navb-icons>svg{
cursor: pointer;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  } 
  .right-h-content{
    display: flex;
    align-items: center;
    gap: 30px;
  }

  
.ul-nav2 {
  display: flex;
  gap: 10px;
}
.ul-nav2 > li {
  cursor: pointer;
}
.ul-nav>a{
  color: gray;
}
.ul-nav2>a{
  color: gray;
}
.log-out-btn-n1{

  color: gray;
  font-size: 14px;
}
.nav-cart-count {
  display: flex;
  color: gray;
  align-items: center;
}

@media only screen and (max-width: 550px) {
.ul-nav{
  display: none;
}
.ul-nav2{
  display: none;

}
.right-h-content {
  display: flex;
  gap: 10px;
}
.nav-d1 {
  align-items: unset;
  justify-content: space-between;

}

.menu-op1{
  display: unset !important;
}
}
.menu-op1{
  display: none;
}

.head-mobile-menu {
  padding: 25px 20px;
}

.close-icon-nav {
  display: flex;
  justify-content: flex-end;
}


.side-bar-menu-ul {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 0px;
  border-bottom: 0.3px solid #80808052;
}

.side-bar-menu-ul > li {
  color: black;
  font-size: 20px;
  font-weight: 700;
}

.right-h-user1 {
  display: flex;

  align-items: center;
  justify-content: space-between;
}


.right-h-user {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-name-nav-black {
  font-size: 16px;
  color: black;
  font-weight: 100;
  cursor: pointer;
}
.side-nav-btn-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 60px;
}
.side-nav-btn-div > button:active {
  transform: scale(0.8);
  transition: all 0.5s;
}

.btn-side-nav1 {
  border: 1px solid black;
  padding: 6px 20px;
  color: white;
  background: black;
  border-radius: 17px;
  transition: all 0.5s;
}

   
.btn-side-nav2 {
  border: 0.3px solid gray;
  padding: 6px 20px;
  color: black;
  background: white;
  border-radius: 17px;
  transition: all 0.5s;
}














  
  .cust-d1{
    padding: 100px 50px 400px 50px;
  }
  .card-cus-img{
    width: 300px;
  }
  .card-d-cus{
    display: flex;
    justify-content: space-evenly;
 
  }
  .h-overs{
    text-align: center;
    font-size: 20px;
    font-weight: 100;
    text-transform: uppercase;
  }
  .card-cus-1{
    cursor: pointer;
  }


  
@media only screen and (max-width: 730px) {

  .card-d-cus{
    display: flex;
    justify-content: space-evenly;
 
  }
  .card-cus-img {
    width: 200px;
}
.cust-d1 {
  padding: 100px 20px 100px 20px;
}
  }
  
  
  @media only screen and (max-width: 500px) {

    .card-d-cus {
      display: flex;
      justify-content:  space-between;
  }
    .card-cus-img {
      width: 150px;
  }
  .cust-d1 {
    padding: 100px 5px 200px 9px;

  }
  .h-overs {
   font-size: 15px;

}
    }
    
    
.pro-card {
    width: 300px;
    cursor: pointer;
}
.pro-card-img {
    width: 300px;
    height: 371px;
    object-fit: cover;
}
.brand-name-c {
    font-size: 15px;
    text-transform: uppercase;
}
.card-p-1 {
    font-size: 12px;
}
.card-price {
    font-size: 17px;
}
.cott-div{

}
.cot-h1 {
    font-size: 12px;
    font-weight: 100;
    border: 1px solid lightgray;
    padding: 5px;
    width: fit-content;
    text-transform: uppercase;
}
.card-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.flex-pro-1 {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 650px) {
    .pro-card {
        width: 250px !important;
    }
    .pro-card-img {
        width: 250px !important;
            height:auto !important;
        object-fit: unset !important;
    }
  
}


@media only screen and (max-width: 554px) {
    .pro-card {
        width: 180px !important;
    }
    .pro-card-img {
        width:180px !important;
            height:auto !important;
        object-fit: unset !important;
    }
    .flex-pro-1 {
    

        justify-content: space-evenly;
    }
  
}


@media only screen and (max-width: 415px) {
    .pro-card {
        width: 150px !important;
    }
    .pro-card-img {
        width:150px !important;
            height:auto !important;
        object-fit: unset !important;
    }
    .flex-pro-1 {
    
        
        justify-content: space-evenly;
    }
  
}

@media only screen and (max-width: 330px) {
    .pro-card {
        width: 120px !important;
    }
    .pro-card-img {
        width:120px !important;
            height:auto !important;
        object-fit: unset !important;
    }
    .flex-pro-1 {
    
        
        justify-content: space-evenly;
    }
  
}